@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~react-perfect-scrollbar/dist/css/styles.css";
@import "~c3/c3.min.css";
@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

@import "components/kit/vendors/antd/style";
@import "components/kit/vendors/bootstrap/style";
@import "components/kit/vendors/perfect-scrollbar/style";
@import "components/kit/vendors/nprogress/style";
@import "components/kit/vendors/chartist/style";
@import "components/kit/vendors/chartist-tooltip-plugin/style";
@import "components/kit/vendors/jvectormap/style";
@import "components/kit/vendors/react-sortable-tree/style";
@import "components/kit/vendors/react-draft-wysiwyg/style";

@import "components/kit/core/style"; // Clean UI KIT styles
@import "components/cleanui/styles/style"; // Clean UI styles

.ant-form-item-control-input-content {
  display: flex;
}

@font-face {
  font-family: SansProLight;
  src: url("/fonts/SourceSansPro-Light.ttf");
}

@font-face {
  font-family: SansProRegular;
  src: url("/fonts/SourceSansPro-Regular.ttf");
}

.treeDot {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

main {
  background-color: #fafafa;
}

.mainHeader {
  font-weight: bold !important;
  font-size: 16px !important;
  color: black !important;
}

.mainHeaderContent {
  font-weight: bold !important;
  font-size: 15px !important;
}

.container {
  background: rgba(255, 255, 255, 255) none repeat scroll 0% 0%;
  margin: 5px 0px;
  padding: 8px;
  box-shadow: 0 0 1px 1px #aaa;
  margin-bottom: 7px;
}

// override default variables for BaseTable
$table-prefix: BaseTable;
@import "~react-base-table/es/_BaseTable.scss";

.#{$table-prefix}__header-cell:first-child {
  padding-left: 7.5px !important;
}

.#{$table-prefix} {
  .#{$table-prefix}__row-cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .#{$table-prefix}__header-cell-text {
    width: 100% !important;
    font-weight: 600;
  }

  .#{$table-prefix}__row:nth-child(odd) {
    background-color: white;
  }


  .#{$table-prefix}__body {
    overflow-x: hidden !important;
  }

  .#{$table-prefix}__row-cell {
    div {
      width: 100%;
    }
  }

  .#{$table-prefix}__row-cell:first-child {
    padding-left: 7.5px !important;
  }

  .#{$table-prefix}__sort-indicator {
    position: absolute;
    right: 0;

  }

  .rowStyle {
    width: 100%;
    height: 100%;
    display: flex;
    margin-left: 8px;
    align-items: center;
    word-break: break-word;
  }

  //Blue
  .rowStyle1 {
    background: #e9fbff;
  }

  //Green
  .rowStyle2 {
    background: #bbddbb;
  }

  //Yellow
  .rowStyle3 {
    background: #f8f693;
  }

  //Orange
  .rowStyle4 {
    background: #f39a51;
  }

  //Red
  .rowStyle5 {
    background: #f09595;
  }

  //Lila
  .rowStyle6 {
    background: #fdc9fd;
  }
}

.card-header-flex {
  justify-content: space-between;
}

.language-list-item {
  cursor: pointer;
  margin: 5px;
}

.language-list-item:hover {
  text-decoration: underline;
}

.language-selected {
  margin: 20px;
}

.hr-text {
  display: flex;
  flex-direction: row;
}

.hr-text hr,
.hr-text p {
  flex: 1;
  width: 100%;
}


.BaseTable__header-cell, .BaseTable__row-cell {
  overflow: visible !important;
}

.BaseTable .BaseTable__sort-indicator {
  right: unset;
  left: -4.5px;
}
